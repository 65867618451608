<template>
  <div>
    <h3>{{ this.isAddMode ? "Opret" : "Rediger" }} profil</h3>

    <vx-card no-shadow class="mt-8">
      <h2 v-if="!this.isAddMode" class="mb-6">Profil for {{profile.type}}</h2>
<!-- {{JSON.stringify(profile)}} {{isAddMode}} -->
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Finansenhed</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.organisationsId"
            placeholder="Finansenhed..."
            :disabled="!this.isAddMode"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Finansenhedsnavn</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.organisationNavn"
            placeholder="Finansenhedsnavn..."
            :disabled="!this.isAddMode"
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Titel</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.type"
            placeholder="Titel..."
            :disabled="!this.isAddMode"
          />
        </div>
      </div>

       <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Funktions gruppe</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
            <vs-select
              class="w-full"
              placeholder="Vælg funktionsgruppe"
              label-placeholder="Funktionsgruppe"
              autocomplete
              v-model="profile.funktionsGruppe"
            >
            <vs-select-item
                :key="index"
                :value="item.afdelingRolle"
                :text="item.afdelingRolle"
                v-for="(item, index) in this.funktionsGruppe"> 
            </vs-select-item>
            </vs-select>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>eposHR</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
            <vs-select
              placeholder="Vælg"
              v-model="profile.eposHR"
              class="profile-prompt-select w-full"
              autocomplete>
              <vs-select-item
                v-for='(item, index) in [{ text: "Brugeradgang", value: "Brugeradgang" }
                ,{ text: "Brugeradgang, fanebladsadministrator", value: "Brugeradgang, fanebladsadministrator" }
                ,{ text: "Lederadgang", value: "Lederadgang" }
                ,{ text: "Administratorrettigheder", value: "Administratorrettigheder" }
                ,{ text: "Administratorrettigheder, Lederadgang", value: "Administratorrettigheder, Lederadgang" }
                ]'
                :key="index"
                :value="item.value"
                :text="item.text"
              />
            </vs-select>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>eposLøn</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.eposLøn"
            placeholder="eposLøn..."
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Overenskomst</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.overenskomst"
            placeholder="Overenskomst..."
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Firmakort</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
            <vs-select
              placeholder="Vælg"
              v-model="profile.firmakort"
              class="profile-prompt-select w-full"
              autocomplete>
              <vs-select-item
                v-for='(item, index) in [{ text: "Nej", value: "Nej" }
                ,{ text: "25.000", value: "25.000" }
                ,{ text: "50.000", value: "50.000" }
                ,{ text: "100.000", value: "100.000" }
                ]'
                :key="index"
                :value="item.value"
                :text="item.text"
              />
            </vs-select>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>DigitalSignatur</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.digitalSignatur"
            placeholder="DigitalSignatur..."
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>P-Drev</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.pDrev"
            placeholder="P-Drevsrettigheder..."
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>B-Drev</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.bDrev"
            placeholder="B-Drevsrettigheder..."
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>F-Drev</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.fDrev"
            placeholder="F-Drevsrettigheder..."
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>M-Drev</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.mDrev"
            placeholder="M-Drevsrettigheder..."
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>BusinessOnline</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.businessOnline"
            placeholder="BusinessOnline..."
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Tricom</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.tricom"
            placeholder="Tricom..."
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Ibinder</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <div class="center">
            <vs-radio
              v-model="profile.ibinder"
              vs-name="radioiBinder"
              :vs-value="'Ja'"
              class="pr-8"
            >
              Ja
            </vs-radio>
            <vs-radio
              v-model="profile.ibinder"
              vs-name="radioiBinder"
              :vs-value="'Nej'"
            >
              Nej
            </vs-radio>
            </div>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Etray</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.etray"
            placeholder="Etray..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>BluePrism</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.bluePrism"
            placeholder="BluePrism.."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Power BI</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.powerBI"
            placeholder="PowerBi..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>FlexiCapture</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.flexicapture"
            placeholder="FlexiCapture.."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Dreambroker</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.dreambroker"
            placeholder="Dreambroker..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Miele Vision 2</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.mieleVision2"
            placeholder="Miele Vision 2..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Management SQL</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.managementSQL"
            placeholder="Management SQL..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Unik Bolig</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.unik"
            placeholder="Unik Bolig..."
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Hardwarepakke</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
            <vs-select
              class="w-full"
              placeholder="Vælg hardwarepakke"
              label-placeholder="Hardwarepakke"
              autocomplete
              v-model="profile.hardware"
            >
            <vs-select-item
                :key="index"
                :value="item.name"
                :text="item.name"
                v-for="(item, index) in this.hardware"> 
            </vs-select-item>
            </vs-select>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Hardware tilvalg</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.hardwareTilvalg"
            placeholder="Hardware tilvalg..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Betalt mobil</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.betaltMobil"
            placeholder="Betalt mobil..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Eazy projekt</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.easy"
            placeholder="Eazy projekt..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Acubiz</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.acubiz"
            placeholder="Acubiz..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Visio</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.visio"
            placeholder="Visio..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>ProjektPro</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.projektPro"
            placeholder="ProjektPro..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Yammer</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.yammer"
            placeholder="Yammer..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>FTP adgang</span>
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <div class="center">
            <vs-radio
              v-model="profile.ftp"
              vs-name="radioFtp"
              :vs-value="'Ja'"
              class="pr-8"
            >
              Ja
            </vs-radio>
            <vs-radio
              v-model="profile.ftp"
              vs-name="radioFtp"
              :vs-value="'Nej'"
            >
              Nej
            </vs-radio>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>E-drev</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <div class="center">
            <vs-radio
              v-model="profile.eDrev"
              vs-name="radioEdrev"
              :vs-value="'Ja'"
              class="pr-8"
            >
              Ja
            </vs-radio>
            <vs-radio
              v-model="profile.eDrev"
              vs-name="radioEdrev"
              :vs-value="'Nej'"
            >
              Nej
            </vs-radio>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Z-drev</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <div class="center">
            <vs-radio
              v-model="profile.zDrev"
              vs-name="radioZdrev"
              :vs-value="'Ja'"
              class="pr-8"
            >
              Ja
            </vs-radio>
            <vs-radio
              v-model="profile.zDrev"
              vs-name="radioZdrev"
              :vs-value="'Nej'"
            >
              Nej
            </vs-radio>
            </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>G-drev</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <div class="center">
            <vs-radio
              v-model="profile.gDrev"
              vs-name="radioGdrev"
              :vs-value="'Ja'"
              class="pr-8"
            >
              Ja
            </vs-radio>
            <vs-radio
              v-model="profile.gDrev"
              vs-name="radioGdrev"
              :vs-value="'Nej'"
            >
              Nej
            </vs-radio>
            </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>K-drev</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <div class="center">
            <vs-radio
              v-model="profile.kDrev"
              vs-name="radioKdrev"
              :vs-value="'Ja'"
              class="pr-8"
            >
              Ja
            </vs-radio>
            <vs-radio
              v-model="profile.kDrev"
              vs-name="radioKdrev"
              :vs-value="'Nej'"
            >
              Nej
            </vs-radio>
            </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>R-drev</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <div class="center">
            <vs-radio
              v-model="profile.rDrev"
              vs-name="radioRdrev"
              :vs-value="'Ja'"
              class="pr-8"
            >
              Ja
            </vs-radio>
            <vs-radio
              v-model="profile.rDrev"
              vs-name="radioRdrev"
              :vs-value="'Nej'"
            >
              Nej
            </vs-radio>
            </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>U-drev</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <div class="center">
            <vs-radio
              v-model="profile.uDrev"
              vs-name="radioUdrev"
              :vs-value="'Ja'"
              class="pr-8"
            >
              Ja
            </vs-radio>
            <vs-radio
              v-model="profile.uDrev"
              vs-name="radioUdrev"
              :vs-value="'Nej'"
            >
              Nej
            </vs-radio>
            </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>W-drev</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <div class="center">
            <vs-radio
              v-model="profile.wDrev"
              vs-name="radioWdrev"
              :vs-value="'Ja'"
              class="pr-8"
            >
              Ja
            </vs-radio>
            <vs-radio
              v-model="profile.wDrev"
              vs-name="radioWdrev"
              :vs-value="'Nej'"
            >
              Nej
            </vs-radio>
            </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>X-drev</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <div class="center">
            <vs-radio
              v-model="profile.xDrev"
              vs-name="radioXdrev"
              :vs-value="'Ja'"
              class="pr-8"
            >
              Ja
            </vs-radio>
            <vs-radio
              v-model="profile.xDrev"
              vs-name="radioXdrev"
              :vs-value="'Nej'"
            >
              Nej
            </vs-radio>
            </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Y-drev</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <div class="center">
            <vs-radio
              v-model="profile.yDrev"
              vs-name="radioYdrev"
              :vs-value="'Ja'"
              class="pr-8"
            >
              Ja
            </vs-radio>
            <vs-radio
              v-model="profile.yDrev"
              vs-name="radioYdrev"
              :vs-value="'Nej'"
            >
              Nej
            </vs-radio>
            </div>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Stamdatabase - Sharepoint</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.sharepointStamdatabase"
            placeholder="Stamdatabase..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Sharepointrettigheder</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.sharepointRettigheder"
            placeholder="SharePointrettigheder..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>ControlManager</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.controlManager"
            placeholder="Controlmanager..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>TDC Scale Supervisor</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.tdcScaleSupervisor"
            placeholder="TDC Scale Supervisor..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>TopDesk</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.topdesk"
            placeholder="TopDesc..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Trello</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.trello"
            placeholder="Trello..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>DefGo</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.defgo"
            placeholder="DefGo..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Oracle Driver</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.oracleDriver"
            placeholder="Oracle driver..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Fællespostkasser</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.fællespostKasser"
            placeholder="Fællespostkasser..."
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Visual Studio</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="profile.visualStudio"
            placeholder="Visual Studio..."
          />
        </div>
      </div>
    </vx-card>
    <!-- Register Profile Button -->
    <div class="mt-4">
      <backbutton></backbutton>
      <vs-button
        @click="saveData"
        id="save-button"
        class="ml-4 mr-auto mt-2"
      >
        {{ this.isAddMode ? "Opret" : "Opdater" }}
      </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profileId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      query: "*",
      profile: {
        organisationsId: "",    
        organisationNavn: "",
        type: "",
        funktionsGruppe: "", //ADDED 13.july.2022 - HKM
        eposHR: "",
        eposLøn: "",
        overenskomst: "",
        firmakort: "",
        digitalSignatur: "",
        pDrev: "",
        bDrev: "",
        fDrev: "",
        mDrev: "",
        businessOnline: "",
        tricom: "",
        ibinder: "",
        etray: "",
        bluePrism: "",
        powerBI: "",
        flexicapture: "",
        dreambroker: "",
        mieleVision2: "",
        managementSQL: "",
        unik: "",
        hardware: "",
        betaltMobil: "",
        hardwareTilvalg: "",
        easy: "",
        acubiz: "",
        visio: "",
        projektPro: "",
        yammer: "",
        fTP: "",
        eDrev: "",
        zDrev: "",
        gDrev: "",
        kDrev: "",
        rDrev: "",
        uDrev: "",
        wDrev: "",
        xDrev: "",
        yDrev: "",
        sharepointStamdatabase: "",
        sharepointRettigheder: "",
        controlManager: "",
        tDCScaleSupervisor: "",
        topdesk: "",
        trello: "",
        defgo: "",
        oracleDriver: "",
        fællespostKasser: "",
        visualStudio: ""

      },

      funktionsGruppe: [],
      hardware: []
    };
  },
  computed: {
    isAddMode() {
      return !this.profileId;
    }
  },
  methods: {
    getFunktionsGruppe: function () {
    this.$vs.loading();
    // Henter alle funktions grupper. 
    this.$http
      .get(process.env.VUE_APP_API + `/FunctionGroups/all`)
      .then((resp) => {
        this.funktionsGruppe = resp.data;
        console.log(`${resp.data.length} Funktions gruppe fetched`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.$vs.loading.close();
      });
    },

    
    getHardware: function () {
    this.$vs.loading();
    // Henter alle Hardware pakker. 
    this.$http
      .get(process.env.VUE_APP_API + `/hardware`)
      .then((resp) => {
        this.hardware = resp.data;
        console.log(`${resp.data.length} hardware fetched`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.$vs.loading.close();
      });
    },

    saveData: function() {
      this.$vs.loading();

      if (this.profileId) {
        this.updateProfile(this.profile);
      } else {
        this.registerProfile(this.profile);
      }
    },
    updateProfile(payload) {
      this.$http
        .post(process.env.VUE_APP_API + "/profiles", payload) // bemærk at det er RegisterProfile som kaldes. Der tjekkes om Titlen findes.
        .then(resp => {
          if (resp.data.success === true) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Profil opdateret"
            });

            this.$router.push({ name: "profiles" }).catch(() => {});
          } else {
            console.log(resp.data.error);

            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Profil kunne ikke opdateres: " + resp.data.error
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    registerProfile(payload) {
      this.$http
        .post(process.env.VUE_APP_API + "/profiles", payload)
        .then(resp => {
          if (resp.data.success === true) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Profil oprettet"
            });

            this.$router.push({ name: "profiles" }).catch(() => {});
          } else {
            console.log(resp.data.error);

            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Profil kunne ikke oprettes: " + resp.data.error
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    this.getFunktionsGruppe();
    this.getHardware();

    if (!this.isAddMode) {
      this.$vs.loading();

      this.$http
        .get(process.env.VUE_APP_API + "/profiles/" + this.profileId)
        .then(resp => {
          this.profile = resp.data;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    }
    else{
      var copyId = this.$route.query.copyFromId
      if (copyId){
      this.$vs.loading();

      this.$http
        .get(process.env.VUE_APP_API + "/profiles/" + copyId)
        .then(resp => {
          this.profile = resp.data;
          this.profile.profileId = ""
          this.profile.type = ""
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
select {
  height: 40px;
  padding: 10px;
  border-color: lightgrey;
  border-radius: 5px;
  color: #626262;
}
.horizontal-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.selector {
  width: 40% !important;
}
.selector select {
  width: 100% !important;
}
</style>
